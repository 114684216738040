import React, { useState } from 'react';
import './portfolio.css';

const portfolioData = [
  {
    id: 1,
    title: 'JOVM School Website',
    description: 'This project was designed to collect fee after admin login, The website includes Form for collecting fee, after submit it will save data in database and generate invoice. It is searchable and view PDF and can take printout of invoice and more functionality.',
    image: 'portfolio-1.png',
    link: 'https://jovmtekari.online/',
  },
  {
    id: 2,
    title: 'E-commerce with API',
    description: 'This site has been created with live API server And Categorywise. This website is Responsive according to devices and SEO friendly.',
    image: 'portfolio-2.png',
    link: 'https://matloobcode.github.io/API-Shopping-site/',
  },
  {
    id: 3,
    title: 'Hotel Booking',
    description: 'It is Ctreated with Bootstrap according to hotel facilities, Also it calculate total at submit time. It is fully Responsive for all devices.',
    image: 'portfolio-3.png',
    link: 'https://matloobcode.github.io/Hotel-Booking/',
  },
  {
    id: 4,
    title: 'Project Four',
    description: 'It is Ctreated React.js with Bootstrap and sass like E-Commerce Site, Also it calculate total at submit time. It is fully Responsive for all devices.',
    image: 'portfolio-4.png',
    link: 'https://zeenat-mobile.web.app/',
  },
];

export function Portfolio() {
  return (
    <div className="portfolio-container">
      <h2 className="portfolio-title">Our Portfolio</h2>
      <div className="portfolio-grid">
        {portfolioData.map((project) => (
          <PortfolioItem key={project.id} project={project} />
        ))}
      </div>
    </div>
  );
}

function PortfolioItem({ project }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const characterLimit = 100;

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderDescription = () => {
    if (isExpanded) {
      return project.description;
    }
    return project.description.length > characterLimit
      ? `${project.description.substring(0, characterLimit)}...`
      : project.description;
  };

  return (
    <div className="portfolio-card">
      <img src={project.image} alt={project.title} className="portfolio-image" />
      <div className="portfolio-content">
        <h3>{project.title}</h3>
        <p>{renderDescription()}</p>
        {project.description.length > characterLimit && (
          <button onClick={handleToggleExpand} className="read-more-btn">
            {isExpanded ? 'Show Less' : 'Read More'}
          </button>
        )}
        <a href={project.link} className="portfolio-button">View Project</a>
      </div>
    </div>
  );
}
