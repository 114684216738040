import React from 'react';
import './about.css';

export function About() {
  return (
    <div className="about-container">
      <h2 className="text-center h1">About Us</h2>
      <div className="about">
        <div className="about-text">
          <p>
            MountGit is a leading web development company, specializing in creating elegant and functional websites that deliver outstanding results. With years of experience in the industry, we pride ourselves on our ability to bring our clients' visions to life through innovative design and cutting-edge technology.
          </p>
        </div>
        <img src="A1.png" alt="About Us" className="about-image" />
      </div>
    </div>
  );
};

