import React, { useRef } from 'react';
import './contact.css';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export function Contact(){

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    toast("🚀Email Send Successfully!🥳");

    emailjs.sendForm('service_t9zeooe', 'template_nwntg9a', form.current, 'V6c-mHcmJ0le53VEi')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };

  return (
    <div className="contact-container">
      <div className="contact-form glass-effect">
        <h2>Contact Us</h2>
        <form ref={form} onSubmit={sendEmail}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="from_name" className="form-control" placeholder="Your Name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="from_email" className="form-control" placeholder="Your Email" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" className="form-control" placeholder="Your Message" rows="4" required></textarea>
          </div>
          <button type="submit" className="btn btn-outline-secondary submit-btn">Send Message</button>
        </form>
      </div>
        <ToastContainer />
    </div>
  );
};
