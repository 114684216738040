import React from 'react';
import './footer.css';

export function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section about">
          <h5 className="footer-title">About MountGit</h5>
          <p>
            MountGit is a premier web development company offering top-notch website creation, development, and hosting services. We aim to bring your digital vision to life with elegance and efficiency.
          </p>
        </div>
        <div className="footer-section contact">
          <h5 className="footer-title text-center">Contact Us</h5>
          <ul className="footer-links">
            <li><a className='footer-links bi bi-phone-flip' href="tel:+917903287161"> +91 7903287161</a></li>
            <li><a className='footer-links bi bi-phone-flip' href="tel:+917903287161"> +91 9113906558</a></li>
            <li><a className='footer-links bi bi-envelope-paper-heart' href="mailto:mountgit@gmail.com"> mountgit@gmail.com</a></li>
          </ul>
        </div>
        <div className="footer-section follow-us-quick-links">

          <div className="social">
            <h5 className="footer-title">Follow Us</h5>
            <div className="social-links">
              <a href="https://www.instagram.com/mountgit?igsh=MTd1Ym1neTJmMDZtMA=="><i className="bi bi-instagram"></i></a>
              <a href="https://www.facebook.com/profile.php?id=61564464422075"><i className="bi bi-facebook"></i></a>
              <a href="https://www.twitter.com"><i className="bi bi-twitter"></i></a>
              <a href="https://www.linkedin.com"><i className="bi bi-linkedin"></i></a>
            </div>
          </div>

          <div className="quick-links">
            <h5 className="footer-title">Quick Links</h5>
            <ul className="footer-links">
              <li><a href="/">Home</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/services">Services</a></li>
              <li><a href="/portfolio">Portfolio</a></li>
              <li><a href="/projects">Projects</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </div>

        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 MountGit. All rights reserved.</p>
      </div>
    </footer>
  );
}
