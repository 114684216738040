import React from 'react';
import './home.css';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import { About } from '../About/About';
import { Services } from '../Services/services';
import { Portfolio } from '../Portfolio/portfolio';
import { Projects } from '../Projects/projects';
import { Contact } from '../Contact/contact';

export function Home() {
  return (
    <div>
      <div className="home-container">
      <h1 className="home-title">Welcome to MountGit</h1>
      <TypeAnimation
        sequence={[
          'Elegant websites.',
          1000,
          'Functional designs.',
          1000,
          'Outstanding results.',
          1000,
        ]}
        wrapper="div"
        className="home-typeable-text"
        repeat={Infinity}
      />
      <p className="home-text">
        We specialize in creating elegant and functional websites that deliver outstanding results. Let's build something great together.
      </p>
      <button className="home-button"><Link className='text-white' to="/about">Get Started</Link></button>
    </div>

    <About />
    <Services />
    <Portfolio />
    <Projects />
    <Contact />
    </div>
  );
};
