import React, { useState } from 'react';
import './projects.css';

const projectsData = [
  {
    id: 1,
    title: 'JOVM',
    description: 'This project was designed to collect fee after admin login, The website includes Form for collecting fee, after submit it will save data in database and generate invoice. It is searchable and view PDF and can take printout of invoice and more functionality.',
    link: 'https://jovmtekari.online/',
  },
  {
    id: 2,
    title: 'Project Alpha',
    description: 'A powerful tool for data analytics and visualization, designed for modern businesses. This project empowers organizations to make data-driven decisions through intuitive interfaces, comprehensive data integration, and real-time reporting capabilities.',
    link: '#',
  },
  {
    id: 3,
    title: 'Project Beta',
    description: 'A cutting-edge mobile app that streamlines project management for teams. This solution offers robust task management, collaboration features, and seamless integration with popular productivity tools to keep teams organized and on track.',
    link: '#',
  },
  {
    id: 4,
    title: 'Project Gamma',
    description: 'A responsive website template tailored for creative agencies and startups. With a focus on aesthetics and functionality, this project provides a versatile platform for showcasing portfolios, client testimonials, and service offerings.',
    link: '#',
  },
  {
    id: 5,
    title: 'Project Delta',
    description: 'A responsive website template tailored for creative agencies and startups. With a focus on aesthetics and functionality, this project provides a versatile platform for showcasing portfolios, client testimonials, and service offerings.',
    link: '#',
  },
];

export function Projects() {
  return (
    <div className="projects-container">
      <h2 className="projects-title">Our Projects</h2>
      <div className="row">
        {projectsData.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </div>
    </div>
  );
}

function ProjectCard({ project }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const characterLimit = 100;

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderDescription = () => {
    if (isExpanded) {
      return project.description;
    }
    return project.description.length > characterLimit
      ? `${project.description.substring(0, characterLimit)}...`
      : project.description;
  };

  return (
    <div className="col-md-6 col-lg-4 mb-4">
      <div className="project-card card h-100 shadow-sm">
        <div className="card-body">
          <h3 className="card-title">{project.title}</h3>
          <p className="card-text">{renderDescription()}</p>
          {project.description.length > characterLimit && (
            <button onClick={handleToggleExpand} className="read-more-btn">
              {isExpanded ? 'Show Less' : 'Read More'}
            </button>
          )}
          <a href={project.link} className="view-project">View Project</a>
        </div>
      </div>
    </div>
  );
}
