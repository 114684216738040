import React from 'react';
import './services.css';

export function Services(){
  const serviceList = [
    {
      title: 'Web Development',
      description: 'We create custom, responsive websites that are both beautiful and functional.',
      icon: 'fas fa-code',
    },
    {
      title: 'E-commerce Website',
      description: 'Building robust e-commerce platforms for seamless online shopping experiences.',
      icon: 'fas fa-shopping-cart',
    },
    {
      title: 'Hosting & Maintenance',
      description: 'Reliable hosting with regular updates and security checks to keep your site running smoothly.',
      icon: 'fas fa-server',
    },
    {
      title: 'Logo Design',
      description: 'Creating unique, professional logos to help your brand stand out.',
      icon: 'fas fa-pen-nib',
    },
    {
      title: 'Social Ads',
      description: 'Maximizing your online reach with targeted social media advertising.',
      icon: 'fas fa-ad',
    },
    {
      title: 'SEO Optimization',
      description: 'Boost your online presence with our expert SEO services.',
      icon: 'fas fa-chart-line',
    },
    {
      title: 'Consultation & Strategy',
      description: 'We offer strategic advice to help your business succeed online.',
      icon: 'fas fa-lightbulb',
    },
  ];

  return (
    <div className="services-container">
      <h2 className="services-title">Our Services</h2>
      <div className="services-grid">
        {serviceList.map((service, index) => (
          <div className="service-card" key={index}>
            <i className={`${service.icon} service-icon`}></i>
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
