import React, { useState } from "react";
import "./whatsApp.css"; // Import the CSS file for styling

export function WhatsappDialog() {
  const [isOpen, setIsOpen] = useState(false);

  const openWhatsApp = () => {
    window.open("https://wa.me/+917903287161", "_blank"); // Open WhatsApp Web
  };

  return (
    <div className="whatsapp-container">
      <div
        className={`whatsapp-icon ${isOpen ? "active" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" onClick={openWhatsApp}
          alt=""
        />
      </div>
      {isOpen && (
        <div className="whatsapp-dialog">
          <div className="whatsapp-header">
            <h2>MountGit</h2>
          </div>
          <div className="whatsapp-body">
            <p>
              Need dynamic website let's contact? <br />
              Start a chat with us on WhatsApp!
            </p>
            <button onClick={openWhatsApp}>Start WhatsApp Chat</button>
          </div>
        </div>
      )}
    </div>
  );
}
